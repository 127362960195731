import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import { FaCheck } from 'react-icons/fa';
import PhotoGallery from '../components/Gallery';

const Dziwnowek2 = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/A2-2.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
    }
    mobileImage: file(relativePath: { eq: "images/photos/A2-2-mobile.jpg" }) {
      childImageSharp {
          fluid(quality: 50){
          ...GatsbyImageSharpFluid
          }
      }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];


  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">APARTAMENTY DZIWNÓWEK</h1>
          <div className="content-container">

            <h1>Apartament Amber 2</h1>
            <p>Apartament Amber 2 o powierzchni 30 m2, mieści się na 1 piętrze. Posiada salon z rozkładaną sofą, w pełni wyposażony aneks kuchenny, osobną sypialnię z łożem małżeńskim, szafą i TV, łazienkę, przedpokój z szafą oraz balkon z meblami ogrodowymi. Przynależy do niego miejsce postojowe nr 11 w garażu podziemnym, do którego można zjechać windą. Widok z balkonu na dziedziniec osiedla.</p>

            <h1>Wyposażenie:</h1>
            <ul>
              <li><FaCheck />klimatyzacja</li>
              <li><FaCheck />smart TV 49 cali - salon</li>
              <li><FaCheck />wi - fi </li>
              <li><FaCheck />płyta indukcyjna - 2 palniki</li>
              <li><FaCheck />zmywarka</li>
              <li><FaCheck />ekspres do kawy</li>
              <li><FaCheck />czajnik elektryczny</li>
              <li><FaCheck />toster</li>
              <li><FaCheck />lodówka z zamrażalnikiem</li>
              <li><FaCheck />kuchenka mikrofalowa</li>
              <li><FaCheck />zestaw naczyń do swobodnego przygotowania i spożycia każdego posiłku</li>
              <li><FaCheck />stół jadalniany dla 4 osób</li>
              <li><FaCheck />stolik kawowy</li>
              <li><FaCheck />smart TV 32’ w sypialni</li>
              <li><FaCheck />kabina prysznicowa, umywalka, wc</li>
              <li><FaCheck />pralka oraz suszarka na prania</li>
              <li><FaCheck />suszarka do włosów</li>
              <li><FaCheck />kpl ręczników</li>
              <li><FaCheck />odkurzacz</li>
              <li><FaCheck />sofa z możliwością rozłożenia</li>
              <li><FaCheck />łoże małżeńskie</li>
              <li><FaCheck />żelazko i deska do prasowania</li>
              <li><FaCheck />parawan i namiot plażowy oraz zestaw zabawek dla dzieci</li>
            </ul>

            <p style={{ marginTop: 20 }}>W apartamencie Amber 2 <b>akceptujemy</b> zwierzęta.</p>

          </div>

        </section>
      </Fade>


      <Fade up>
        <section className="photos" id="section3">
          <h1>Galeria</h1>
          <div className="photos">
            <PhotoGallery galleryType='apartament2' />
          </div>
        </section>
      </Fade>

    </Layout>
  )
}



export default Dziwnowek2

